import classNames from "classnames";
import Image from "next/image";
import { useFormatter, useTranslations } from "next-intl";

import CrossCountry from "~/types/crossCountry";
import imageUtils from "~/utils/image-utils";
import productUtils from "~/utils/product-utils";

import Icon from "../common/icon";
import styles from "./product-color-selector.module.scss";

type Props = {
  product: CrossCountry.Product;
  current: CrossCountry.ChildrenProduct;
  onSelect: () => void;
  removeBorder?: boolean;
  disableBtn?: boolean;
};

export default function ProductColorSelector(props: Props) {
  const t = useTranslations();
  const formatter = useFormatter();

  const numberOfColors = props.product.children?.length || 0;
  const color = props.current.color ?? "";
  const goccia = productUtils.getProductDroplet(props.current.product_media?.media)?.url;

  return (
    <div className={classNames(styles.container, props.removeBorder ? styles.removeBorder : undefined)}>
      <div className={styles.current}>
        {goccia ? (
          <Image
            src={goccia || imageUtils.transparentPixel}
            alt={color}
            width={16}
            height={16}
            className={styles.image}
            loader={imageUtils.evaImageLoader}
          />
        ) : null}
        <p className={styles.name}>{color}</p>
      </div>
      {numberOfColors > 1 &&
        (!props.disableBtn ? (
          <button
            type="button"
            className={styles.available}
            aria-label={t("a11y.more_colors", { number: numberOfColors - 1 })}
            onClick={() => props.onSelect()}
          >
            <p>{formatter.number(numberOfColors - 1, { signDisplay: "always" })}</p>
            <Icon name="chevron-right" width={16} height={16} />
          </button>
        ) : (
          <div
            className={classNames(styles.available, styles.noBtn)}
            aria-label={t("a11y.more_colors", { number: numberOfColors - 1 })}
          >
            <p>{formatter.number(numberOfColors - 1, { signDisplay: "always" })}</p>
          </div>
        ))}
    </div>
  );
}
