import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { ReactNode } from "react";

import CrossCountry from "~/types/crossCountry";
import { basicAnimationStates } from "~/utils/animation-utils";
import imageUtils from "~/utils/image-utils";
import intlUtils from "~/utils/intl-utils";
import objectUtils from "~/utils/object-utils";
import productUtils from "~/utils/product-utils";

import styles from "./product-colors-dialog.module.scss";

export type PropsProductColor = {
  product: CrossCountry.ChildrenProduct;
  onSelect: (product: CrossCountry.ChildrenProduct) => void;
  isSelected?: boolean;
  children?: ReactNode;
  isGift: boolean;
};

export function ProductColor({ product, isSelected = false, onSelect, children, isGift }: PropsProductColor) {
  const goccia = productUtils.getProductDroplet(product?.product_media?.media);

  return (
    <button
      key={product.product_id}
      className={classNames(
        styles.color,
        isSelected && styles.activeColor,
        !product.has_stock && !isGift ? styles.outOfStock : undefined
      )}
      onClick={() => onSelect(product)}
    >
      <div className={styles.imageContainer}>
        <Image
          alt={product.color || ""}
          src={goccia?.url || imageUtils.transparentPixel}
          width={20}
          height={20}
          loader={imageUtils.evaImageLoader}
        />
      </div>
      {children}
    </button>
  );
}

export type PropsProductColorsDialog = {
  current: CrossCountry.ChildrenProduct;
  products: CrossCountry.ChildrenProduct[];
  onSelect: (product: CrossCountry.ChildrenProduct) => void;
  onChange: () => void;
  showColorList?: boolean;
  isGift?: boolean;
};

export default function ProductColorsDialog({
  current,
  products,
  onSelect,
  onChange,
  showColorList,
  isGift = false,
}: PropsProductColorsDialog) {
  const t = useTranslations();
  const priceFormatter = intlUtils.usePriceFormatter();

  return (
    <AnimatePresence key={`listenabled-${showColorList}`}>
      <motion.div
        initial={basicAnimationStates.off}
        animate={basicAnimationStates.on}
        exit={basicAnimationStates.off}
        className={classNames(styles.content, { [styles.singleColor]: !showColorList })}
      >
        {showColorList ? (
          products?.map((product) => (
            <ProductColor
              key={product.product_id}
              product={product}
              isGift={isGift}
              isSelected={objectUtils.deepEqual(current, product) ? true : false}
              onSelect={() => onSelect(product)}
            >
              <p className={styles.colorName}>{product.color}</p>
              <p className={styles.price}>
                {priceFormatter.currency(product.display_price, { currency: product.currency_id })}
              </p>
            </ProductColor>
          ))
        ) : (
          <ProductColor
            key={current.product_id}
            product={current}
            onSelect={() => onChange()}
            isGift={isGift}
            isSelected
          >
            <p className={styles.colorName}>{current.color}</p>
            <p className={styles.changeButton}>{t("generic.changecount", { count: products.length })}</p>
          </ProductColor>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
