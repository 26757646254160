const gridUtils = {
  /**
   * String usable in the `next/image` `sizes` prop.
   * Defines the sizes for images that are displayed in a grid.
   *
   * @see https://nextjs.org/docs/api-reference/next/image#sizes
   */
  imageSizes: `(max-width: 1280px) 50vw, 25vw`,
};

export default gridUtils;
